<template>
  <v-container fluid>
    <h1 class="title font-weight-bold">
      <span
        v-translate
        translate-context="MSpacesStatus view title"
      >
        Status per space
      </span>

      <v-tooltip
        bottom
        content-class="pa-0"
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            small
            class="mt-n4"
            v-on="tooltip"
          >
            info
          </v-icon>
        </template>

        <v-card
          dark
          flat
        >
          <v-card-title
            v-translate
            translate-context="MSpacesStatus view definitions card title"
          >
            Definitions
          </v-card-title>

          <v-card-text>
            <div
              translate
              translate-context="MSpacesStatus view definition"
              class="m-state-enabled d-inline-block px-2 mb-4"
            >
              Not started
            </div>

            <br>

            <div
              translate
              translate-context="MSpacesStatus view definition"
              class="m-state-started d-inline-block px-2 mb-4"
            >
              Started
            </div>

            <br>

            <div
              translate
              translate-context="MSpacesStatus view definition"
              class="m-state-completed d-inline-block px-2 mb-4"
            >
              Complete
            </div>

            <br>

            <div
              translate
              translate-context="MSpacesStatus view definition"
              class="d-inline-block px-2 mb-4"
              style="border: 2px solid white;"
            >
              This takt's goal
            </div>

            <div
              class="mb-4"
            >
              <v-icon
                color="error"
              >
                error
              </v-icon>
              <span
                translate
                translate-context="MSpacesStatus view definition"
              >
                = Interruption
              </span>
            </div>

            <div>
              <v-icon
                color="error"
              >
                pan_tool
              </v-icon>
              <span
                translate
                translate-context="MSpacesStatus view definition"
              >
                = Pace-setting task
              </span>
            </div>
          </v-card-text>

          <v-divider
            dark
            class="mt-4"
          />

          <v-card-title
            v-translate
            translate-context="MSpacesStatus view definitions card title"
          >
            Axes
          </v-card-title>

          <v-card-text>
            <ul>
              <li
                translate
                translate-context="MSpacesStatus view definition"
              >
                Spaces on the vertical axis
              </li>

              <li
                translate
                translate-context="MSpacesStatus view definition"
              >
                A space's work packages on the horizontal axis
              </li>

              <li
                translate
                translate-context="MSpacesStatus view definition"
              >
                A work package's status in the cell (complete / all)
              </li>
            </ul>
          </v-card-text>

          <v-divider
            dark
            class="mt-4"
          />

          <v-card-title
            v-translate
            translate-context="MSpacesStatus view definitions card title"
          >
            Other
          </v-card-title>

          <v-card-text
            v-translate
            translate-context="MSpacesStatus view definitions card content"
          >
            The view filters out tasks that are in the "Skipped" state
          </v-card-text>
        </v-card>
      </v-tooltip>
    </h1>

    <p
      v-translate="{ telephoneLink: viewSubtitleTelephoneLink }"
      translate-context="MSpacesStatus view subtitle"
      class="mb-2 body-2"
      render-html="true"
    >
      The status of your site space by space. Improvement suggestions?
      Call us %{ telephoneLink }
    </p>

    <v-menu
      v-if="!!selectedWp"
      :value="true"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y
      :close-on-content-click="false"
      :close-on-click="false"
      :transition="false"
    >
      <v-card
        v-click-outside="onClickOutside"
        width="400px"
      >
        <v-card-title>
          <span
            v-translate
            translate-context="MSpacesStatus view tasklist card title"
          >
            Tasks
          </span>

          <v-spacer />

          <v-btn
            icon
            @click="selectedWp = null"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <m-loader-circular
            v-if="$wait.is('loading todos')"
            class="mb-12"
            :text="loadingTodosLoaderText"
            :size="48"
          />

          <div
            v-else
            style="max-height: 500px; overflow-y: auto;"
          >
            <m-todos-feed-card
              v-for="(todo, i) in todos"
              :key="i"
              :todo="todo"
              class="mb-2 mx-auto"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

    <m-loader-circular
      v-if="loading"
    />

    <div
      v-show="!loading"
      class="overflow-x-auto"
      style="max-height: calc(100vh - 140px);"
    >
      <table
        ref="table"
        class="sticky-table"
      >
        <thead>
          <tr>
            <th />

            <th
              v-for="i in longestPassageCount"
              :key="i"
              v-translate="{ wpIndex: i }"
              translate-context="MSpacesStatus view table column"
              class="caption-xs text-uppercase white elevation-2"
              :class="{
                highlight: i === xIndex,
              }"
            >
              WP%{ wpIndex }
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(space, i) in spaces"
            :key="i"
          >
            <th
              class="caption-xs text-uppercase white elevation-4"
              :class="{
                highlight: i === yIndex,
              }"
            >
              {{ space.address }}
            </th>

            <td
              v-for="(wp, k) in wpsForSpace[space.id]"
              :key="`i-${i}-k-${k}`"
            >
              <div
                v-if="wp"
                class="caption-xxs relative cell"
                :class="{
                  'm-state-enabled': wp.enabled,
                  'm-state-started': wp.started,
                  'm-state-completed': wp.completed,
                }"
                :style="{
                  border: wp.tactTargetNow === currentTact.taktIndex ? '2px solid var(--v-primary-base)' : '',
                }"
                :data-space-id="space.id"
                :data-wp-index="k"
                :data-x-index="k"
                :data-y-index="i"
              >
                <div
                  class="cell-overlay"
                  :class="{
                    show: selectedWp && selectedWp.virtualId === wp.virtualId
                  }"
                />

                <div
                  style="pointer-events: none;"
                >
                  <span
                    v-if="wp.totalCount > 0"
                  >
                    {{ wp.completedCount }}
                    /
                    {{ wp.totalCount }}
                  </span>

                  <v-icon
                    v-if="wp.requiresSupervision"
                    small
                    color="error"
                    style="position: absolute; bottom: -2px; right: 0; z-index: 1;"
                  >
                    pan_tool
                  </v-icon>

                  <v-icon
                    v-if="wp.interrupted"
                    small
                    color="error"
                    style="position: absolute; top: -6px; right: 0; z-index: 1;"
                  >
                    error
                  </v-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MTodosFeedCard from '@/components/MTodosFeedCard';

  export default {
    components: { MTodosFeedCard },
    data: () => ({
      loading: true,
      menuX: null,
      menuY: null,
      selectedWp: null,
      xIndex: null,
      yIndex: null,
    }),

    computed: {
      ...mapGetters({
        spaces: 'project/spaces/spaces',
        longestPassageCount: 'project/progress/longestPassageCount',
        progressBySpaces: 'project/progress/progressBySpaces',
        currentTact: 'project/tacts/currentTact',
        todos: 'project/todos/todos',
      }),

      /**
       * Fills in the empty workPackages.
       *
       * @returns {[]}
       */
      wpsForSpace() {
        const result = this.spaces.reduce((acc, space) => {
          if (!acc[space.id]) {
            acc[space.id] = [];
          }

          const wps = this.progressBySpaces[space.id];

          if (!wps) return acc;

          for (let i = 0; i < this.longestPassageCount; i += 1) {
            const wp = wps.find(w => w.wpOrder === i) || null;
            acc[space.id].push(wp);
          }

          return acc;
        }, {});

        return result;
      },

      viewSubtitleTelephoneLink() {
        return this.$gettextInterpolate(
          this.$pgettext('MSpacesStatus view subtitle phonenumber', '<a href="%{ phoneNumber }">here</a>.'),
          {
            phoneNumber: 'tel: +358407008296',
          },
        );
      },

      loadingTodosLoaderText() {
        return this.$pgettext('Loader', 'Fetching tasks...');
      },
    },

    async mounted() {
      await Promise.all([
        this.loadSpaces(),
        this.loadCurrentTact(),
        this.loadProgressBySpaces(),
        this.loadUsers({ projectId: this.$projectId, groupBy: 'id' }),
      ]);

      this.addListeners();

      this.$nextTick(() => {
        this.loading = false;
      });
    },

    beforeDestroy() {
      this.destroyListeners();
    },

    methods: {
      ...mapActions({
        loadCurrentTact: 'project/tacts/loadCurrentTact',
        loadUsers: 'users/loadUsers',
        loadSpaces: 'project/spaces/loadSpaces',
        loadProgressBySpaces: 'project/progress/loadProgressBySpaces',
      }),

      ...mapWaitingActions('project/todos', {
        loadTodos: 'loading todos',
      }),

      addListeners() {
        this.$refs.table.addEventListener('click', this.onCellClick, { passive: true });
        this.$refs.table.addEventListener('mouseover', this.onCellMouseOver, { passive: true });
      },

      destroyListeners() {
        this.$refs.table.removeEventListener('click', this.onCellClick);
        this.$refs.table.removeEventListener('mouseover', this.onCellMouseOver);
      },

      onClickOutside(e) {
        if (e.target.classList.contains('cell')) return;
        this.selectedWp = null;
      },

      onCellMouseOver(e) {
        this.xIndex = null;
        this.yIndex = null;

        if (!e.target.classList.contains('cell')) return;

        const { dataset } = e.target;
        const xIndex = parseInt(dataset.xIndex, 10) + 1;
        const yIndex = parseInt(dataset.yIndex, 10);

        /**
         * Highlights the header cells when hovering data cells.
         */
        this.xIndex = xIndex;
        this.yIndex = yIndex;
      },

      onCellClick(e) {
        if (!e.target.classList.contains('cell')) return;

        const {
          right: x,
          bottom: y,
        } = e.target.getBoundingClientRect();

        const {
          spaceId,
          wpIndex,
        } = e.target.dataset;

        const wp = this.wpsForSpace[spaceId][wpIndex];

        // Toggle
        if (this.selectedWp && this.selectedWp.virtualId === wp.virtualId) {
          this.selectedWp = null;
        } else {
          this.menuX = x;
          this.menuY = y;
          this.selectedWp = wp;

          this.loadTodos({
            spaceIds: spaceId,
            wpOrders: wp.wpOrder,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.sticky-table {
  position: relative;
  border-collapse: collapse;
  text-align: center;

  th {
    min-width: 48px;
    height: 24px;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  tbody th {
    position: sticky;
    left: 0;
    z-index: 2;
    white-space: nowrap;
  }

  .highlight:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
}

.cell-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.175);
  display: none;

  &.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.cell {
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell:hover .cell-overlay {
  display: block;
}
</style>
